import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import {
  faBell,
  faBook,
  faArchive
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sendData, onData, offData } from '../Hooks/Socket';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Badge from 'react-bootstrap/Badge';
import Popover from 'react-bootstrap/Popover';

function Mentions() {
  const [showMentions, setShowMentions] = useState(false);
  const [mentions, setMentions] = useState([]);
  const [numMentionsNotViewed, setNumMentionsNotViewed] = useState(0);
  const [renderLimit, setRenderLimit] = useState(5);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleShowMentions = (e) => {
    setShowMentions(!showMentions);
    setRenderLimit(5);
    setTarget(e.target);
  }

  const getMentions = () => {
    let data = {
      genUserId: localStorage.getItem('genUserId')
    }

    sendData('getMentions', data);
  }

  /**
   * Handles response to getting
   * a user's mentions
   * @param {Array} data response data 
   * @param {String} data[].comment the comment the user has made
   * @param {Date} data[i].timestamp the time the comment was made
   */
  const onGetMentions = (data) => {
    if (data.length > 0) {
      let mentionsArray = [];
      let mentionsNotViewed = 0;

      for (let i = 0; i < data.length; i++) {
        mentionsArray.push({
          id: data[i].id,
          comment: data[i]?.comment?.toString(),
          timestamp: new Date(data[i].timestamp),
          author: data[i].author,
          url: data[i].url,
          viewed: data[i].viewed
        });

        if (!data[i].viewed) {
          mentionsNotViewed++;
        }
      }

      setNumMentionsNotViewed(mentionsNotViewed);
      setMentions(mentionsArray);
    } else {

      let mentionsArray = [];
      setNumMentionsNotViewed(0);
      setMentions(mentionsArray);
    }
  }

  const onMention = (data) => {
    getMentions();
  }

  const markMentionRead = () => {
    setRenderLimit(5);
    setShowMentions(false);

    setNumMentionsNotViewed(numMentionsNotViewed);
  }

  const markMentionsRead = () => {
    setNumMentionsNotViewed(0);

    let data = {
      genUserId: localStorage.getItem('genUserId')
    }

    sendData('mentionsRead', data);
  }

  const archiveMention = (id) => {
    let data = {
      commentId: id,
      genUserId: localStorage.getItem('genUserId')
    }
    sendData('archiveMention', data);
  }

  const archiveMentions = () => {
    setShowMentions(false);

    let data = {
      genUserId: localStorage.getItem('genUserId')
    }
    sendData('archiveMentions', data);
  }

  useEffect(() => {
    getMentions();
    setInterval(() => {
      getMentions()
    }, 60000)
    document.addEventListener('mousedown', (e) => {
      if (!ref.current.contains(e.target)) {
        setRenderLimit(5);
        setShowMentions(false);
      }
    });

    onData('mention', onMention);
    onData('getMentions', onGetMentions)

    return () => {
      offData('mention');
      offData('getMentions');
    }
  }, []);

  return (
    <div ref={ref}>
      <Button variant={numMentionsNotViewed > 0 ? "danger" : "primary"} onClick={handleShowMentions} style={{ marginRight: "1rem" }}>
        <FontAwesomeIcon icon={faBell} size="lg" />
        <Badge style={{ marginLeft: '2px' }}>{numMentionsNotViewed}</Badge>
      </Button>
      <Overlay
        show={showMentions}
        target={target}
        container={ref.current}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom" style={{ maxHeight: "500px", maxWidth: "350px", overflowY: "auto" }}>
          <Popover.Title as="h3">Mentions</Popover.Title>
          {mentions.length > 0 ?
            <>
              <Popover.Content style={{ paddingTop: "0px", paddingBottom: "5px", paddingLeft: "0px", paddingRight: "0px" }}>
                {mentions.slice(0, renderLimit).map((mention, i) => {
                  return (
                    <div key={i} style={{ borderBottom: "2px solid black", padding: "5px", backgroundColor: mention.viewed ? "white" : "#ADD8E6" }}>
                      <h6>{mention.author} mentioned you!</h6>
                      <p>{mention.comment}</p>
                      <Link to={mention.url} onClick={() => markMentionRead()}>View Mention</Link>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Link to="#" onClick={() => archiveMention(mention.id)}>Archive Mention</Link>
                      <p>{mention.timestamp.toLocaleString()}</p>
                    </div>
                  );
                })}
              </Popover.Content>
              <Popover.Content className="justify-content-between align-items-center" style={{ padding: "0px", display: "flex" }}>
                <Button
                  variant="danger"
                  onClick={archiveMentions}
                  style={{ margin: '5px', display: 'inline' }}
                >
                  <FontAwesomeIcon icon={faArchive} size="lg" style={{ marginRight: '5px' }} />
                  Archive All
                </Button>
                {mentions.length > renderLimit ?
                  <span style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={() => setRenderLimit(renderLimit + 5)}>Load More...</span>
                  :
                  null
                }
                <Button
                  variant="danger"
                  onClick={markMentionsRead}
                  style={{ margin: '5px', display: 'inline' }}
                >
                  <FontAwesomeIcon icon={faBook} size="lg" style={{ marginRight: '5px' }} />
                  View All
                </Button>
              </Popover.Content>
            </>
            :
            <Popover.Content style={{ padding: "5px" }}>
              <h6>No new mentions at this time!</h6>
            </Popover.Content>
          }
        </Popover>
      </Overlay>
    </div>
  );
}

export default Mentions;
